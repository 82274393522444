.alreadyUsed{
    background: #FFCEC6;
    font-size: 14px;
    padding: 5px;
}

.phone-input {
    color:#757575
}

.switch-to-login{
    color: #CF4520;
    text-decoration: underline;
    font-size: 14px;
    cursor: pointer;
}

.phone-country-code{
    height: 50px;
}

.flag-icon{
    height: 14.27px;
    margin-right: 5px;
}
