.customSelect {
  position: relative;
  font-family: sans-serif;
  user-select: none;
  display: inline-block;
  min-width: 90px;
  width: auto;
  margin-right: 4px;

  .selectedWrapper {
    position: relative;
    
    .selected {
      //border: 1px solid #ccc;
      border-radius: 4px;
      //padding: 8px 30px 8px 12px;
      cursor: pointer;
      background: #fff;
      display: flex;
      align-items: center;
      white-space: nowrap;
      
      .selectedValue {
        color: #333;
        font-weight: 500;
      }

      .arrow {
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #666;
        transition: transform 0.2s ease;
      }
    }
  }

  &.open {
    .selectedWrapper .selected .arrow {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  .options {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100%;
    width: max-content;
    //margin-top: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: #fff;
    z-index: 1000;
    max-height: 200px;
    overflow-y: auto;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);

    .option {
      display: flex;
      justify-content: space-between;
      padding: 8px 12px;
      cursor: pointer;
      transition: background-color 0.2s;
      white-space: nowrap;

      &:hover {
        background-color: #f5f5f5;
      }

      &.selected {
        background-color: #e6e6e6;
      }

      .country {
        color: #333;
        margin-right: 8px;
      }

      .code {
        color: #666;
      }
    }
  }
} 