.spinner {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 4px solid #ccc;
    border-top-color: #999;
    border-radius: 50%;
    animation: spin 1s ease-in-out infinite;
}

.mydiv{
    display: flex;
    flex-direction: row;
    position: relative;
}

.my-btn{
    position: absolute;
    top: 8px;
    right: 0;
    border: none;
    min-width: 40px;
}
.my-input{
    overflow: hidden;
    align-self: start;
    padding-right: 35px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
}
    100% {
        transform: rotate(360deg);
}
}