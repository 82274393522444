.mydiv{
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
}

.my-btn{
    position: absolute;
    top: 8px;
    right: 0;
    border: none;
    min-width: 40px;
}

.email-phone-input{
    //border-top-left-radius: 0;
    //border-bottom-left-radius: 0;
    color:#757575;
}


