.phone-submit-btn {
    width: 100%;
    padding: 13px;
    margin-top: 16px;
}

.phone-country-code{
    height: 50px;
    padding: 20px;
}

.flag-icon {
    height: 14.27px;
    margin-right: 5px;
}
