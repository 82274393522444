
.continueAnotherWay{
    text-align: center;
    margin-top: 8px;
    color: #757575;
}

a.continueAnotherWayLink, a.continueAnotherWayLink:hover {
    color: #757575;
    background-color: transparent;
    text-decoration: underline;
    cursor: pointer;
  }
  

.other-login-options button{
    width:  100%;
    background-color: white;
    border: rgba(0, 0, 0, 0.07155);
    border-radius: 1.5rem;
    color: black;
    padding: 13px;
    margin-bottom: 10px;
    text-align: left;
    padding-left: 32px;
}

.other-login-options .icon-img{
    padding-right:  0.3em;
    vertical-align: middle;
    margin-right: 16.6666%;
}
