@import "~bootstrap/scss/bootstrap";


@font-face {
    font-family: 'ilFontFedraSans400';
    src: url('./../../fonts/fedra-sans/FedraSansStd-Book.otf') format('opentype');
}

@font-face {
    font-family: 'ilFontFedraSans500';
    src: url('./../../fonts/fedra-sans/FedraSansStd-Medium.otf') format('opentype');
}

@font-face {
    font-family: 'ilFontFedraSerif500';
    src: url('./../../fonts/fedra-serif/FSerStdA-Medium.otf') format('opentype');
}

body {
    font-family: 'ilFontFedraSans400', sans-serif;
}

.title{
    font-family: 'ilFontFedraSans500', sans-serif;
    font-size: 26px;
    font-weight: 500;
}

.customTitle{
    font-family: 'ilFontFedraSerif500', sans-serif;
}

.title1{
    font-size: 18px;
    font-weight: 400;
}

.description{
    font-size: 14px;
    font-weight: 400;
}

.submit-btn-style{
    background-color: #000000;
    color: #FFFFFF;
}

.w-100 {
    width: 100% !important;
}

.il-form-control {
    @extend .form-control !optional;
    padding: 12px;
}

.il-form-select {
    @extend .form-select !optional;
    padding: 12px;
}

.il-text-danger {
    margin: 2px 0 0 2px;
    color: #FF0000;
    font-size: 12px;
    font-weight: 400;
}

.il-primary-btn {
    @extend .btn !optional;
    @extend .btn-primary !optional;
    width: 80%;
    padding: 13px;
    background-color: #CF4520;
    color: white;
    font-size: 16px;
    font-weight: 500;
    border: none;
    border-radius: 6px;

    &:disabled, &[disabled] {
        background-color: #000;
        color: #fff;
        opacity: 0.35;
    }
}

.il-secondary-btn {
    @extend .btn !optional;
    @extend .btn-secondary !optional;
    width: 80%;
    padding: 13px;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid #CF4520;
    border-radius: 6px;
    background-color: #f4f0e5;
    color: #CF4520;

    &:hover {
        background-color: #0069d9;
    }

    &:disabled, &[disabled] {
        background-color: #000;
        color: #fff;
        opacity: 0.35;
    }
}

.required:after {
    content: " *";
    color: red;
}

.phone-country-code {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    color: #757575;
}

.phone-country-code-black {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    color: black;
    background: white;
}

.otp-input-style {
    width: 50% !important;
    height: 45px;
    border-radius: 1px;
    border: 0;
    border-bottom: 4px solid #B4B4B4;
    margin-left: 8px;
    margin-right: 8px;
    background: transparent;
    font-size: 20px;
    outline: none;

    &[type=number] {
        -moz-appearance: textfield; /* For Firefox */
    }

    &[type=number]::-webkit-inner-spin-button, &[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
    }

}

.otp-input-wrapper{
    padding-bottom: 8px;
}

.otp-error-field{
    display: flex;
    justify-content: center;
}

.otp-container {
    position: relative;
    margin-top: 16px;
}

