
.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo {
    height: 70px;
    /*width: 200px;*/
}

.title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.name-warning {
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    color: #4A4A4A;
}

.tick-container {
    position: absolute;
    right: 12px;
    top: 12px;
}

/* for hr line*/

.hr-sect {
    display: flex;
    flex-basis: 100%;
    align-items: center;
    color: rgba(0, 0, 0, 0.35);
    margin: 8px 0px;
}
.hr-sect:before,
.hr-sect:after {
    content: "";
    flex-grow: 1;
    background: rgba(0, 0, 0, 0.35);
    height: 1px;
    font-size: 0px;
    line-height: 0px;
    margin: 0px 8px;
}

