.error-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    //height: 100vh;
    background-color: rgba(1, 1, 1, 0.8);
    z-index: 9999;
}



.error-box {
    max-width: 800px;
    padding: 20px;
    text-align: center;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.alert-icon {
    width: 50px;
    height: 50px;
    flex-grow: 1;
    flex-shrink: 0;
    align-self: center;
    margin-right: 20px;
}

.alert-content {
    display: flex;
    flex-direction: row;
    margin: 20px;
}


.error-message {
    color: #333;
    font-size: 16px;
    text-align: justify;
    align-self: center;
    flex-grow: 9;
}

.close-button {
    background-color: #CF4520;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}
