.spinner {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 4px solid #ccc;
    border-top-color: #999;
    border-radius: 50%;
    animation: spin 1s ease-in-out infinite;
}

.mydiv{
    display: flex;
    position: relative;
}

.my-btn{
    position: absolute;
    top: 8px;
    right: 0;
    border: none;
    min-width: 40px;
}
.phone-input{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    color:#757575;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
}
    100% {
        transform: rotate(360deg);
}
}

.pf-cc{
    padding: 0.375rem 0.75rem;
}

.flag-icon{
    height: 14.27px;
    margin-right: 5px;
}