.password-container{

}

.password-controls{
  position: relative;
}

.show-password {
  position: absolute;
  left: 0;
  top: 8px;

  input[type="checkbox"] {
    margin-right: 8px;
  }

}