.footer {
  text-align: center;
  font-size: 14px;
}

.red-link {
  color: #CF4520;
  text-decoration: none;
}

.red-link:hover {
  text-decoration: underline;
}
