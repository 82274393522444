.my-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    min-height: 100vh;
    padding-top: 5%;
    padding-bottom: 5%;
    background: #F4F0E5;
}


.my-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 16px;
    width: 450px;
    min-height: 450px;
    //border: 1px solid #0dcaf0;
    //border-radius: 5px;
    /*background: #0dcaf0;*/
}

@media screen and (max-width: 576px) {
    .my-card {
        width: 100%;
        margin: 0;
    }

    .my-container {
        background: #F4F0E5;
        padding: 0;
    }
}

