.indian-view {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.phone-submit-btn {
    width: 100%;
    padding: 13px;
    margin-top: 16px;
}

/* for country flag in mobile number input*/
.phone-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flag-icon {
    height: 14.27px;
    margin-right: 5px;
}

.phone-country-code{
    height: 50px;
    padding: 20px;
}

