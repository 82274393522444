.div-otp-resend{
    margin-top : 16px;
    display: flex;
    justify-content: center;
}

.resend-otp-btn {
    color: #CF4520;
    text-decoration: underline;
    font-size: 14px;
    cursor: pointer;
}

.resend-otp-timer { 
    font-size: 14px;
}

.resend-otp-timer .time-remaining{
    color:#CF4520; 
}