.nudge-skip{
    text-decoration: underline;
    cursor: pointer;
    color: inherit;
}

.nudge-skip:hover{
    color: inherit;
}

.nudge-claim-phone, .nudge-claim-email{
    text-decoration: underline;
    cursor: pointer;
    color: inherit;
}

.nudge-claim-phone:hover, .nudge-claim-email:hover{
    color: inherit;
}

.already-used{
    background: #FFCEC6;
    padding: 5px;
}

.submit-btn{
    margin: 0 auto;
}

.switch-to-login{
    color: #CF4520;
    text-decoration: underline;
    cursor: pointer;
}