.verify-btn {
  width: 100%;
}

.footer {
  text-align: center;
  font-size: 14px;
}

.change-input{
  color: #CF4520;
  text-decoration: underline;
  cursor: pointer;
}