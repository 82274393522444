
.password-container{

}

.password-controls{
    position: relative;
}

.show-password {
    position: absolute;
    left: 0;
    top: 8px;

    input[type="checkbox"] {
        margin-right: 8px;
    }

}

.forgot-password {
    position: absolute;
    right: 0;
    top: 8px;

    a {
        color: #FF0000;
        font-size: 14px;
        font-weight: 400;
        //text-decoration: none;
    }
}

.submit-btn{
    width: 100%;
}